.our_program {
  width: 100%;
  background-color: #eee;
  margin: 0;
  background-image: url(../assets/program.jpg);
  background-size: 100% 100%;
  background-attachment: fixed;
  position: relative;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: #1b7dc0e3;
  // }
  .program_content {
    position: relative;
    z-index: 5;
    padding: 50px 0;
    .title {
      text-align: center;
      margin: 0;
      font-size: 34px;
      font-weight: 600;
      color: #1b7dc0;
    }
    .boxs {
      position: relative;
      overflow: hidden;
      margin: 58px 0 0 0;
      .line {
        position: absolute;
        height: 96%;
        z-index: 20;
        top: 50%;
        left: 50%;
        width: 4px;
        background: #fff;
        transform: translate(-50%, -50%);
        // &::after,
        // &::before {
        //   content: "";
        //   left: 50%;
        //   transform: translateX(-50%);
        //   width: 12px;
        //   height: 12px;
        //   border: 2px solid #fff;
        //   position: absolute;
        //   border-radius: 50%;
        // }
        // &:after {
        //   top: -11px;
        // }
        // &:before {
        //   bottom: -11px;
        // }
      }
      .f {
        margin-top: 45px !important;
      }
      .l {
        margin-bottom: 45px !important;
      }
      .box {
        clear: both;
        width: 50%;
        position: relative;
        padding: 0;
        margin-bottom: 77px;
        background: #1b7dc0d9;
        &:after {
          content: "";
          position: absolute;
          top: 36px;
          height: 16px;
          background: #ffffff;
          width: 16px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 36px;
          border-width: 8px;
          border-style: solid;

          z-index: 5;
          background: none;
          width: 16px;
        }
        .top_ {
          margin-bottom: 3px;
          overflow: hidden;
          padding: 6px 0;
          position: relative;
          h4 {
            color: #fff;
            margin: 0;
            font-size: 21px;
            position: relative;
            top: 8px;
            right: 9px;
          }
          img {
            float: right;
            width: 36px;
          }
        }
        p {
          color: #fff;
          margin: 0;
          font-size: 14px;
        }
      }
      .left {
        float: left;
        padding: 15px 57px 21px 15px;
        &:after {
          right: 0;
        }
        &:before {
          right: 16px;
          border-color: transparent #ffffff transparent transparent;
        }
        .top_ {
          img {
            float: right;
            width: 36px;
          }
        }
      }
      .right {
        float: right;
        padding: 15px 15px 21px 57px;
        &:after {
          left: 0;
        }
        &:before {
          left: 16px;
          border-color: transparent transparent transparent #ffffff;
        }
        .top_ {
          h4 {
            text-align: right;
            right: auto;
            left: -9px;
          }
          img {
            float: right;
            width: 36px;
          }
        }
        p {
          text-align: right;
        }
      }
    }
  }

  // Media
  @media (max-width: 575px) {
    & {
      .program_content {
        .boxs {
          .box {
            background: #1b7dc0;
            z-index: 20;
            margin: 0 auto;
            margin-bottom: 59px;
            display: block;
            float: none;
            padding: 15px;
            width: 396px;
            &:after {
              width: 0 !important;
              height: 0 !important;
            }
            &:before {
              content: "";
              border-width: 0;
            }
          }
          .right {
            .top_ {
              h4 {
                text-align: right;
                right: 9px;
              }
              img {
                float: right;
                width: 36px;
              }
            }
            p {
              text-align: right;
            }
          }
        }
      }
    }
  }
  @media (max-width: 364px) {
    .program_content {
      .boxs {
        .box {
          width: 100% !important;
        }
      }
    }
  }
}
