.about_content {
  width: 100%;
  padding-bottom: 60px;
  .head_about {
    width: 100%;
    background-image: url("../assets/hire-me/head.jpg");
    background-size: cover;
    background-position: center;
    padding: 65px 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #3333339e;
    }
    h2 {
      color: #fff;
      font-size: 53px;
      font-weight: 800;
      margin: 15px 0;
      z-index: 2;
      position: relative;
    }
  }
  .content_text {
    margin-top: 60px;
    p {
      color: #333;
      margin: 0 0 20px 0;
      font-size: 17px;
      line-height: 1.7;
      &:last-of-type {
        margin-bottom: 0;
      }
      span {
        color: #222;
        font-size: 19px;
        font-weight: 700;
      }
    }
  }

  // Media
  @media (max-width: 767px) {
    .head_about {
      h2 {
        text-align: center;
      }
    }
    .content_text {
      p {
        font-size: 14px;
        span {
          font-size: 17px;
        }
      }
    }
  }
}
