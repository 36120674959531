.plans_content {
  width: 100%;
  padding: 60px 0;
  .priceItems {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .price-item {
      box-shadow: 0px 3px 10px 0px #00000026;
      background-color: #fff;
      width: 328px;
      border-radius: 4px;
      padding-bottom: 20px;
      overflow: hidden;
      transition: all 0.4s;
      &:hover {
        transform: scale(1.03);
        box-shadow: 0px 3px 23px 4px #00000040;
      }
      .top_sec {
        width: 100%;
        text-align: center;
        padding: 25px 12px;
        background: linear-gradient(to bottom, #1b7dc0 0%, #1b67b3 100%);
        h3 {
          color: #ffff;
          margin: 0;
          font-size: 27px;
        }
        h2 {
          color: #ffff;
          margin: 7px 0;
          font-size: 49px;
        }
        p {
          color: #ffffff9e;
          margin: 0;
          font-size: 14px;
        }
      }
      .center_sec {
        margin: 15px 0;
        ul {
          width: 100%;
          padding: 0;
          margin: 0;
          li {
            padding: 21px 10px;
            color: #000;
            font-size: 15px;
            text-align: center;
            border-bottom: 1px solid #e8e8e8;
            &:last-of-type {
              border-bottom: 0;
            }
          }
        }
      }
      .btm_sec {
        width: 100%;
        a {
          display: flex;
          width: 132px;
          padding: 11px 10px;
          background: #1b7dc0;
          font-size: 16px;
          justify-content: center;
          color: #fff;
          align-items: center;
          border-radius: 3px;
          cursor: pointer;
          margin: 0 auto;
          transition: all 0.3s;
          &:hover {
            box-shadow: 0px 3px 10px 0px #00000087;
          }
        }
      }
    }
  }

  // Media
  @media (max-width: 1199px) {
    .priceItems {
      flex-wrap: wrap;
      .price-item {
        width: 47%;
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    .priceItems {
      .price-item {
        width: 100%;
      }
    }
  }
}
