.contact-content {
  width: 100%;
  padding-bottom: 60px;
  .head_contact {
    width: 100%;
    background-image: url("../assets/contact/head.jpg");
    background-size: cover;
    background-position: center;
    padding: 65px 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #3333339e;
    }
    h2 {
      color: #fff;
      font-size: 53px;
      font-weight: 800;
      margin: 15px 0 0 0;
      position: relative;
      z-index: 2;
    }
  }
  .contact_form {
    width: 757px;
    background-color: #fff;
    z-index: 10;
    margin: 60px auto 0 auto;
    padding: 28px 30px;
    border-radius: 9px;
    overflow: hidden;
    box-shadow: 0 0 12px -3px #333;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -32px;
      left: -32px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #0b67a752;
    }
    h3 {
      color: #333;
      font-size: 20px;
      margin: 0;
      font-weight: 600;
      svg {
        margin-left: 10px;
      }
    }
    .comp {
      margin: 48px 0px 0 0;
      width: 100%;
      display: flex;
      .cont_left {
        width: 50%;
        order: 1;
        form {
          width: 100%;
          input {
            width: 100%;
            height: 42px;
            border: 1px solid #cccccc91;
            margin-bottom: 17px;
            font-size: 14px;
            outline: none;
            text-indent: 8px;
            transition: all 0.2s;
            &:focus {
              border: 1px solid #0b67a7;
              border-radius: 5px;
            }
          }
          textarea {
            width: 100%;
            height: 138px;
            border: 1px solid #cccccc91;
            resize: none;
            font-size: 14px;
            outline: none;
            padding: 10px;
            transition: all 0.2s;
            &:focus {
              border-radius: 5px;
              border: 1px solid #0b67a7;
            }
          }
          button {
            width: 100%;
            height: 42px;
            border: 0;
            background: #1b7dc0;
            color: #fff;
            font-size: 16px;
            margin: 29px 0 0 0;
            outline: none;
          }
        }
      }
      .cont_right {
        order: 2;
        width: 50%;
        padding: 0 37px 0 0;
        position: relative;
        ul {
          padding: 0;
          margin: 0;
          position: absolute;
          top: 70px;
          li {
            margin-bottom: 17px;
            svg {
              color: #1b7dc0;
              font-size: 20px;
              float: right;
              margin: 0 0 0 15px;
              position: relative;
              top: 4px;
            }
            p {
              color: #222;
              font-size: 16px;
              padding-right: 25px;
              margin: 0;
            }
          }
        }
      }
    }
  }

  //Media
  @media (max-width: 1199px) {
    .head_contact {
      height: 360px;
      .social {
        margin: 39px 0 0 0;
      }
    }
  }
  @media (max-width: 783px) {
    .contact_form {
      width: 94%;
    }
  }
  @media (max-width: 579px) {
    .contact_form {
      .comp {
        flex-wrap: wrap;
        .cont_left,
        .cont_right {
          width: 100%;
        }
        .cont_right {
          overflow: hidden;
          padding: 51px 0px 0px 0;
          ul {
            position: unset;
          }
        }
      }
    }
  }
  @media (max-width: 434px) {
    .head_contact {
      height: auto;
    }
    .contact_form {
      margin: 38px auto 0 auto;
    }
  }
}
