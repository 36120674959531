.terms_conditions-content,
.privacy_policy,
.privacy_policy-content {
  width: 100%;
  padding-bottom: 40px;
  .head_privacy {
    background-image: url("../assets/privacy-policy/head.jpg") !important;
  }
  .head_terms,
  .head_privacy {
    width: 100%;
    background-image: url("../assets/terms/head.jpg");
    background-size: cover;
    background-position: center;
    padding: 65px 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #3333339e;
    }
    h2 {
      color: #fff;
      font-size: 53px;
      font-weight: 800;
      margin: 15px 0;
      z-index: 2;
      position: relative;
    }
  }
  .content__ {
    width: 100%;
    padding: 50px 0 0 0;
    h2 {
      margin: 0 0 30px 0;
      font-size: 23px;
      color: #1b7dc0;
    }
    .itm {
      margin-bottom: 20px;
      h4 {
        color: #000;
        margin: 0 0 8px 0;
        font-size: 17px;
        font-weight: 700;
      }
      p {
        color: #000;
        margin: 0;
        font-size: 15px;
        line-height: 1.7;
      }
    }
  }

  // Media
  @media (max-width: 407px) {
    .head_terms,
    .head_privacy {
      h2 {
        font-size: 43px;
        text-align: center;
      }
    }
  }
}
