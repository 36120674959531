.contact {
  width: 100%;
  background: #1b7dc0;
  padding: 100px 0 100px 0;
  h2 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
  }
  .text {
    color: #95c5f4;
    text-align: center;
    font-size: 17px;
    width: 483px;
    margin: 13px auto 0 auto;
  }
  .boxs {
    width: 100%;
    margin: 70px 0 0 0;
    .box {
      .content {
        padding: 25px 30px 20px 30px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        height: fit-content;
        margin-bottom: 15px;
        .img {
          float: right;
          margin: 0 0 0 11px;
          img {
            width: 48px;
            height: 48px;
          }
        }
        h4 {
          color: rgba(255, 255, 255, 0.5);
          font-size: 22px;
          font-weight: 300;
          margin: 0;
          margin-bottom: 3px;
          position: relative;
          top: -3px;
        }
        p {
          color: #fff;
          margin: 0;
          font-size: 17px;
          text-align: revert;
        }
      }
    }
  }

  // Media
  @media (max-width: 507px) {
    .text {
      width: auto;
    }
  }
}
