.hire_content {
  width: 100%;
  padding-bottom: 60px;
  .head_hire {
    width: 100%;
    background-image: url("../assets/about/head.jpg");
    background-size: cover;
    background-position: center;
    padding: 65px 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #3333339e;
    }
    h2 {
      color: #fff;
      font-size: 53px;
      font-weight: 800;
      margin: 15px 0;
      z-index: 2;
      position: relative;
    }
  }
  .form_hire {
    width: 758px;
    background-color: #fff;
    z-index: 10;
    margin: 60px auto 0 auto;
    padding: 28px 30px;
    border-radius: 9px;
    overflow: hidden;
    box-shadow: 0 0 12px -3px #333;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -32px;
      left: -32px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #0b67a752;
    }
    p {
      margin: 0 0 38px 0;
      font-size: 15px;
      text-align: center;
      line-height: 1.6;
      color: #000;
    }
    form {
      width: 100%;
      input {
        width: 100%;
        height: 42px;
        border: 1px solid #cccccc91;
        margin-bottom: 17px;
        font-size: 14px;
        outline: none;
        text-indent: 8px;
        transition: all 0.2s;
        &:focus {
          border: 1px solid #0b67a7;
        }
      }
      textarea {
        width: 100%;
        height: 138px;
        border: 1px solid #cccccc91;
        resize: none;
        font-size: 14px;
        outline: none;
        padding: 10px;
        transition: all 0.2s;
        &:focus {
          border: 1px solid #0b67a7;
        }
      }
      p {
        margin: 0;
        font-size: 12px;
        color: #333;
      }
      button {
        width: 165px;
        height: 42px;
        border: 0;
        background: #1b7dc0;
        color: #fff;
        font-size: 16px;
        margin: 29px auto 0 auto;
        outline: none;
        display: block;
      }
    }
  }

  //Media
  @media (max-width: 1199px) {
    .head_hire {
      height: 360px;
      .social {
        margin: 39px 0 0 0;
      }
    }
  }
  @media (max-width: 991px) {
    .form_hire {
      margin: -72px auto 0 auto;
    }
  }
  @media (max-width: 783px) {
    .form_hire {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .form_hire {
      margin: 50px auto 0 auto;
    }
    .head_hire {
      height: fit-content;
    }
  }
}
