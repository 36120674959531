@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
body {
  direction: rtl;
  font-family: "Cairo", sans-serif !important;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #1b7dc0;
    border: 1px solid #fff;
  }
}
// Nav Bar
@import "layout/navbar.scss";
// Footer
@import "layout/footer.scss";

// To Top
.to_top {
  position: fixed;
  bottom: 62px;
  left: 20px;
  width: 50px;
  height: 50px;
  display: none;
  background: #1390e6;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
  z-index: 3000;
  transition: all 0.3s;
  &:hover {
    background-color: #fff;
    border: 2px solid #1390e6;
    svg {
      color: #1390e6;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
    color: #fff;
    font-size: 23px;
  }
}

/*--------------- Home ------------------*/
.home {
  // Header
  @import "layout/home/header.scss";
  // Why
  @import "layout/home/why.scss";
  // Our Program
  @import "layout/home/our_program.scss";
  // Facts
  // @import "layout/home/facts.scss";
  // Clarification
  @import "layout/home/clarification.scss";
  // Contact
  @import "layout/home/contact.scss";
}

/*--------------- Login && Register ------------------*/
.login {
  // Login
  @import "layout/login/content.scss";
}

/*--------------- Contact us ------------------*/
.contact-us {
  // Contact
  @import "layout/contact/contact_content.scss";
}

/*--------------- Contact us ------------------*/
.about-us {
  // About Content
  @import "layout/about-us/about.scss";
  // Contact Section
  @import "layout/about-us/section_contact.scss";
}

/*--------------- Plans ------------------*/
.plans {
  // Plans Content
  @import "layout/plans/plans_content.scss";
}

/*--------------- Plans ------------------*/
.terms_conditions {
  // Terms Conditions Content
  @import "layout/terms_conditions/terms_conditions.scss";
}

/*--------------- Hire Me ------------------*/
.hire_me {
  // Hire Me Content
  @import "layout/hire-me/hire_content.scss";
}

/*-------------- Balance Recharge ----------*/
.balance-recharge {
  // Balance Recharge Content
  @import "layout/balance/balance-recharge.scss";
}
