header {
  width: 100%;
  height: 580px;
  background-color: #ccc;
  background: url(../assets/header.jpg);
  background-size: 100% 100%;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000c7;
  }
  .content {
    width: 100%;
    z-index: 20;
    position: relative;
    padding: 42px 0;
    h1 {
      text-align: center;
      color: #fff;
      font-size: 54px;
      font-weight: 800;
      margin: 66px 0 33px 0;
    }
    p {
      text-align: center;
      color: #fff;
      font-size: 19px;
      width: 735px;
      margin: 0 auto;
      line-height: 1.6;
      display: block;
    }
    .more {
      display: block;
      width: fit-content;
      z-index: 20;
      margin: 101px auto 0 auto;
      cursor: pointer;
      svg {
        width: 19px;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 20;
    svg {
      fill: #fff;
      margin-top: -1px;
    }
  }

  // Media
  @media (max-width: 858px) {
    .content {
      h1 {
        font-size: 43px;
      }
      p {
        width: fit-content;
      }
    }
  }
  @media (max-width: 644px) {
    .content {
      p {
        font-size: 17px;
      }
    }
  }
  @media (max-width: 364px) {
    & {
      height: 475px;
    }
    .content {
      h1 {
        font-size: 25px;
      }
      p {
        font-size: 14px;
      }
      .more {
        margin: 50px auto 0 auto;
      }
    }
  }
}
