footer {
  width: 100%;
  float: left;
  .footer_content {
    width: 100%;
    width: 100%;
    background-color: #141b23;
    padding: 63px 0 29px 0;
    .row {
      .it {
        padding-top: 17px;
        padding-bottom: 17px;
        .title {
          color: #fff;
          font-size: 18px;
          margin: 0;
        }
        .cont {
          margin: 25px 0 0 0;
          p {
            color: #fff;
            font-size: 14px;
            margin: 3px 0 22px;
          }
        }
      }
      .news {
        .cont {
          form {
            width: 100%;
            margin-top: 0;
            input {
              border-radius: 0;
              width: 90%;
              border: 0;
              border-bottom: 1px solid #5b6065;
              background: #141b23;
              font-size: 13px;
              height: 34px;
              text-indent: 7px;
              color: #fff;
              transition: all 0.2s;
              outline: none;
              &:focus {
                border-bottom: 1px solid #1b7dc0;
              }
            }
            a {
              text-decoration: none;
              width: 91px;
              display: flex;
              background: #1b7dc0;
              color: #fff;
              font-size: 13px;
              margin: 10px 0;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              height: 34px;
              border-radius: 0;
            }
          }
        }
      }
      .infos {
        .content {
          border-top: 4px solid #1b7dc0;
          width: 100%;
          height: 100%;
          background-color: #0f151c;
          text-align: center;
          padding: 20px 10px;
          h3 {
            text-align: center;
            font-size: 22px !important;
          }
          .cont {
            h5 {
              color: #fff;
              font-size: 13px;
              margin: 27px 0 28px 0;
              line-height: 1.8;
            }
            ul {
              padding: 0 !important;
              text-align: center;
              margin: 0;
              li {
                &:first-of-type {
                  p {
                    color: #fff;
                    margin-bottom: 3px;
                    font-size: 14px;
                  }
                }
                &:nth-of-type(2),
                &:last-of-type {
                  p {
                    color: #fff;
                    font-size: 14px;
                    margin: 0;
                    span {
                      font-weight: bold;
                      margin-left: 10px;
                    }
                  }
                }
                &:nth-of-type(2) {
                  p {
                    margin-top: 24px;
                  }
                }
              }
            }
            .socai {
              display: flex;
              margin: 28px 0 6px 0px;
              justify-content: center;
              align-items: center;
              a {
                text-decoration: none;
                width: 35px;
                height: 35px;
                cursor: pointer;
                border: 1px solid #fff;
                border-radius: 7px;
                display: flex;
                align-items: center;
                background-color: #141b23;
                margin-left: 13px;
                justify-content: center;
                transition: all 0.3s;
                &:hover {
                  border: 1px solid #1b7dc0;
                  background-color: #1b7dc0;
                }
                svg {
                  transition: all 0.3s;
                  color: #fff;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
      .links {
        .cont {
          ul {
            width: 100%;
            padding: 0;
            margin: 0;
            li {
              width: 100%;
              &:first-of-type {
                a {
                  padding-top: 0;
                }
              }
              a {
                display: flex;
                width: 100%;
                font-size: 15px;
                cursor: pointer;
                padding: 5px 13px 5px 0;
                position: relative;
                transition: all 0.2s;
                text-decoration: none;
                color: #fff;
                &:hover {
                  color: #1b7dc0;
                }
                svg {
                  color: #1b7dc0;
                  position: absolute;
                  right: 0;
                  font-size: 11px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
      .pay {
        .cont {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            margin-left: 30px;
          }
        }
      }
    }
  }
  .footer_bottom {
    width: 100%;
    padding: 31px 0;
    background-color: #0f151c;
    overflow: hidden;
    h2 {
      float: right;
      margin: 0;
      font-size: 18px;
      color: #fff;
    }
    ul {
      margin: 0;
      float: left;
      padding: 0;
      li {
        float: left;
        &:first-of-type {
          a {
            border-left: 0;
          }
        }
        a {
          color: #ffffff4d;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          text-decoration: none;
          transition: all 0.3s;
          padding: 0 14px;
          border-left: 1px solid #ffffff4d;
          &:hover {
            color: #ccc;
          }
        }
      }
    }
  }

  //Media
  @media (max-width: 991px) {
    .footer_bottom {
      h2 {
        width: 100%;
        text-align: right;
      }
      ul {
        float: right;
        display: block;
        padding: 13px 0;
        li {
          float: right;
          &:first-of-type {
            a {
              border-left: 1px solid #ffffff4d;
            }
          }
          &:last-of-type {
            a {
              border-left: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 364px) {
    .footer_bottom {
      h2 {
        font-size: 12px;
      }
    }
  }
}
