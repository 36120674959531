.section_contact {
  width: 100%;
  margin: 84px 0 0 0;
  h3 {
    text-align: right;
    margin: 0;
    color: #222;
    font-size: 26px;
  }
  .sections {
    width: 100%;
    display: flex;
    padding: 15px 0;
    .sect {
      width: 50%;
      padding: 17px 0;
      text-align: right;
      h4 {
        color: #1b7dc0;
        margin: 0;
        font-size: 22px;
      }
      p {
        color: #333;
        margin: 11px 0 0 0;
        font-size: 16px;
        span {
          color: #1b7dc0;
        }
      }
    }
  }

  // Media
  @media (max-width: 767px) {
    h3 {
      font-size: 23px;
    }
    .sections {
      flex-wrap: wrap;
      .sect {
        width: 100%;
      }
    }
  }
}
