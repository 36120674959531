.login_content {
  width: 100%;
  padding: 142px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/login/back.jpg");
  background-size: cover;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e4e4e47a;
    width: 100%;
    height: 100%;
  }
  .form {
    width: 570px;
    position: relative;
    z-index: 20;
    box-shadow: 0 0 8px -1px #0808085c;
    background-color: #fff;
    border-radius: 7px;
    display: flex;
    .login-register-left {
      width: 33%;
      order: 1;
      background-color: #1b7dc0;
      padding: 33px 0;
      .logo {
        width: 100%;
        padding: 0 0px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 138px;
        }
      }
      .links_tab {
        width: 100%;
        padding: 0;
        margin: 56px 0 0 0;
        li {
          width: 100%;
          color: #ffffffb0;
          padding: 9px 15px;
          font-size: 15px;
          cursor: pointer;
          position: relative;
          transition: all 0.2s;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-width: 7px;
            border-color: transparent transparent transparent #fff;
            left: 0;
            border-style: solid;
            transition: all 0.2s;
            opacity: 0;
          }
          &.active {
            background: #ffffff2e;
            color: #fff;
            &:after {
              opacity: 1;
            }
          }
          &:hover {
            color: #fff;
            svg {
              color: #fff;
            }
          }
          &:first-of-type {
            svg {
              transform: rotate(180deg);
            }
          }
          svg {
            font-size: 13px;
            margin-left: 5px;
          }
        }
      }
    }
    .login-register-right {
      width: 67%;
      order: 2;
      padding: 30px 25px;
      background-color: #fff;
      transition: all 0.3s;
      // display: flex;
      // justify-self: center;
      // align-items: center;
      h3 {
        text-align: right;
        margin: 0;
        font-size: 20px;
        color: #1b7dc0;
      }
      .login_form-content,
      .register_form-content,
      .forgotpwd_form-content {
        width: 100%;
        overflow: hidden;
        display: none;
        background-color: #fff;
        p {
          margin: 9px 0 0 0;
          font-size: 13px;
        }
        form {
          width: 100%;
          margin: 34px 0 0 0;
          .inp {
            position: relative;
            height: 34px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 19px;
            transition: all 0.3s;
            input {
              width: 100%;
              border: 0;
              text-indent: 17px;
              height: 100%;
              background-color: rgba(0, 0, 0, 0);
              outline: none;
              font-size: 13px;
            }
            svg {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              font-size: 14px;
              color: #797878;
              transition: all 0.3s;
            }
          }
          .btn_login {
            background: #1b7dc0;
            color: #fff;
            border-radius: 0;
            font-size: 14px;
            padding: 8px 21px;
            outline: none;
            border: 0;
            margin: 11px 0 0 0;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
              background-color: #226bb2;
            }
          }
        }
      }
      .login_form-content {
        display: block;
      }
    }
  }

  // Media
  @media (max-width: 605px) {
    .form {
      width: 97%;
    }
  }
  @media (max-width: 510px) {
    .form {
      flex-wrap: wrap;
      .login-register-left {
        width: 100%;
      }
      .login-register-right {
        width: 100%;
      }
    }
  }
}
