.clarification {
  width: 100%;
  .clarification_content {
    display: flex;
    padding: 60px 0;
    justify-content: center;
    .clar-left {
      display: flex;
      align-items: center;
      justify-content: center;
      order: 2;
      img {
        width: 349px;
        height: 304px;
      }
    }
    .clar-right {
      width: 47%;
      order: 1;
      padding: 31px 33px;
      h3 {
        color: #222;
        margin: 0;
        font-size: 41px;
        line-height: 54px;
        padding: 0;
      }
      ul {
        padding: 0;
        margin: 20px 0 0 0;
        li {
          width: 100%;
          padding: 8px 0;
          img {
            width: 26px;
            float: right;
            font-size: 17px;
            margin-left: 8px;
            position: relative;
            top: -2px;
          }
          p {
            color: #555;
            margin: 0;
            font-size: 17px;
            a {
              color: #3186c4;
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  // Media
  @media (max-width: 1199px) {
    .clarification_content {
      .clar-right {
        width: 72%;
      }
    }
  }
  @media (max-width: 991px) {
    .clarification_content {
      .clar-left {
        display: none;
      }
      .clar-right {
        width: 100%;
      }
    }
  }
  @media (max-width: 505px) {
    .clarification_content {
      .clar-right {
        h3 {
          font-size: 30px;
        }
        ul {
          li {
            p {
              font-size: 15px;
              a {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
