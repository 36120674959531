.balance_content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/balance/back.jpg");
  background-size: cover;
  position: relative;
  height: 600px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3333339e;
  }
  .cont_balance {
    background-color: #fff;
    width: 426px;
    position: relative;
    z-index: 20;
    // border-radius: 10px;
    padding: 28px;
    margin: 0 auto;
    img {
      width: 102px;
      margin: 0 auto;
      display: block;
    }
    h3 {
      color: #222;
      margin: 21px 0 12px 0;
      text-align: center;
      font-size: 30px;
    }
    p {
      color: #333;
      margin: 0;
      text-align: center;
      font-size: 15px;
    }
  }

  // Media
  @media (max-width: 466px) {
    .cont_balance {
      width: 100%;
    }
  }
}
.section_contact {
  margin: 0;
  padding: 65px 0 30px 0;
}
