.nav_bar {
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #eee;
  box-shadow: 0 3px 15px 0px #0000001f;
  .bottom_nav {
    width: 100%;
    clear: both;
    height: 79px;
    .logo {
      padding: 13px 0;
      float: right;
      transition: all 0.3s;
      img {
        width: 111px;
      }
    }
    .logoNav {
      padding: 0;
      img {
        position: relative;
        top: -1px;
        width: 123px;
      }
    }
    .links {
      float: right;
      margin: 0 58px 0 0;
      padding: 19px 0;
      display: flex;
      li {
        float: left;
        a {
          text-decoration: none;
          display: flex;
          transform: perspective(1px) translateZ(0);
          padding: 8px 15px;
          color: #333;
          position: relative;
          font-size: 16px;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 100%;
            right: 0;
            bottom: 0;
            background: #1b7dc0;
            height: 3px;
            transition-property: left;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
          }
          &:hover::after {
            left: 0;
          }
        }
      }
      .down {
        position: relative;
        a {
          padding: 8px 15px 8px 28px;
          position: relative;
        }
        svg {
          position: absolute;
          top: 53%;
          left: 10px;
          font-size: 13px;
          transform: translateY(-50%);
        }
        .drop_ {
          position: absolute;
          box-shadow: 0 0 6px 2px #3333335e;
          background-color: #fff;
          width: 161px;
          top: 44px;
          display: none;
          z-index: 100;
          padding: 0;
          border-radius: 3px;
          height: auto;
          li {
            float: none;
            z-index: 20;
            a {
              display: flex;
              z-index: 20;
              width: 100%;
              text-decoration: none;
              padding: 11px 10px;
              transition: all 0.3s;
              cursor: pointer;
              &:hover {
                background: #eee;
              }
              &:after {
                background: #fff;
                height: 0;
              }
            }
          }
        }
      }
    }
    .btn_left {
      float: left;
      display: flex;
      padding: 21px 0;
      z-index: 200;
      .btns {
        float: left;
        display: flex;
        overflow: hidden;
        order: 2;
        transition: all 0.3s;
        a {
          text-decoration: none;
          padding: 7px 20px;
          border-radius: 2px;
          margin: 0;
          justify-content: center;
          align-items: center;
          background: #1b7dc0;
          color: #fff;
          font-size: 14px;
          display: flex;
          &:first-of-type {
            border: 1px solid #1b7dc0;
            background-color: #fff;
            color: #1b7dc0;
            margin-left: 6px;
          }
        }
      }
      .lang {
        position: relative;
        top: 4px;
        margin: 0 0 0 14px;
        float: right;
        cursor: pointer;
        width: 84px;
        height: 29px;
        padding: 5px 5px;
        position: relative;
        order: 1;
        transition: all 0.3s;
        z-index: 200;
        img {
          width: 21px;
          float: right;
          position: relative;
          top: 3px;
          margin: 0 0 0 4px;
        }
        p {
          margin: 0;
          font-size: 14px;
        }
        .list-lang {
          position: absolute;
          left: 50%;
          z-index: 200;
          transform: translateX(-50%);
          width: 118px;
          background: #fff;
          padding: 0;
          top: 29px;
          z-index: 20;
          margin: 0;
          box-shadow: 0 0 6px 2px #3333335e;
          border-radius: 3px;
          display: none;
          li {
            width: 100%;
            padding: 7px 8px;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
              background: #eee;
            }
            a {
              text-decoration: none;
              img {
                width: 17px;
                position: relative;
                top: 4px;
              }
              p {
                color: #333;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    .section_left {
      float: left;
      display: none;
      height: 100%;
      padding: 25px 0;
      .btns {
        float: left;
        display: flex;
        overflow: hidden;
        transition: all 0.3s;
        a {
          text-decoration: none;
          display: flex;
          height: 29px;
          padding: 0 23px;
          border-radius: 2px;
          font-size: 13px;
          justify-content: center;
          align-items: center;
          background: #1b7dc0;
          color: #ffff;
          &:not(:last-of-type) {
            margin-left: 5px;
          }
          &:first-of-type {
            border: 1px solid #1b7dc0;
            background-color: #ffff;
            color: #1b7dc0;
          }
        }
      }
      .bars {
        width: 23px;
        cursor: pointer;
        float: left;
        margin-right: 17px;
        position: relative;
        top: 4px;
        span {
          background-color: #999;
          border: 0px;
          float: right;
          width: 100%;
          height: 2px;
          &:not(:last-of-type) {
            margin-bottom: 7px;
          }
        }
      }
      .lang {
        float: right;
        cursor: pointer;
        width: 84px;
        height: 29px;
        padding: 5px 5px;
        position: relative;
        top: 0px;
        transition: all 0.3s;
        img {
          width: 21px;
          float: right;
          position: relative;
          top: 3px;
          margin: 0 0 0 4px;
        }
        p {
          margin: 0;
          font-size: 14px;
        }
        .list-lang {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 118px;
          background: #fff;
          padding: 0;
          top: 29px;
          z-index: 20;
          margin: 0;
          box-shadow: 0 0 6px 2px #3333335e;
          border-radius: 3px;
          display: none;
          li {
            width: 100%;
            padding: 7px 8px;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
              background: #eee;
            }
            a {
              text-decoration: none;
              img {
                width: 17px;
                position: relative;
                top: 4px;
              }
              p {
                color: #333;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
  .mobile_details {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66336699;
    display: none;
    .mobile_details-content {
      width: 253px;
      height: 100%;
      padding: 10px;
      background: #fff;
      position: absolute;
      right: -276px;
      top: 0;
      transition: all 0.4s;
      .logo {
        width: 100%;
        margin: 15px 0 25px 0;
        img {
          width: 120px;
        }
      }
      .btns {
        width: 100%;
        display: flex;
        margin: 22px 0 0 0;
        justify-content: space-between;
        a {
          width: 48%;
          text-decoration: none;
          padding: 7px 5px;
          border-radius: 2px;
          margin: 0;
          justify-content: center;
          align-items: center;
          background: #1b7dc0;
          color: #fff;
          font-size: 14px;
          display: flex;
        }
      }
      .links_mob {
        width: 100%;
        padding: 0;
        margin: 0;
        padding: 20px 0;
        .link_mob {
          width: 100%;
          border-bottom: 1px solid #eee;
          &:hover a {
            color: #1b7dc0;
          }
          &:hover svg {
            color: #1b7dc0;
          }
          a {
            display: flex;
            text-align: right;
            padding: 15px 12px;
            color: #222;
            text-decoration: none;
            cursor: pointer;
            transition: all 0.3s;
          }
        }
        .down_mob {
          position: relative;
          a {
            position: relative;
            svg {
              position: absolute;
              top: 53%;
              left: 10px;
              font-size: 13px;
              transform: translateY(-50%);
            }
          }
          .drop_mob {
            padding: 0;
            background: #eeeeee9e;
            display: none;
            li {
              &:hover a {
                color: #333333;
                background-color: #e0e0e0;
              }
              a {
                color: #333;
                padding: 12px 12px;
                font-size: 13px;
              }
            }
          }
        }
      }
      .infos_mob {
        width: 100%;
        margin: 12px 0 0 0;
        .info_mo {
          width: 100%;
          padding: 7px;
          background-color: #e0e0e0;
          text-align: center;
          display: flex;
          justify-content: center;
          margin-bottom: 8px;
          svg {
            position: relative;
            top: 3.3px;
            transition: all 0.3s;
            font-size: 16px;
            color: #333;
            float: right;
            margin: 0 0 0 2px;
          }
          p {
            color: #000;
            margin: 0 6px 0 0;
            transition: all 0.3s;
            font-size: 15px;
            font-family: "Roboto", sans-serif;
            direction: ltr;
          }
        }
      }
      .close {
        position: absolute;
        top: 39px;
        left: 12px;
        cursor: pointer;
        svg {
          color: #3333339e;
          font-size: 26px;
        }
      }
    }
    &.mobi {
      display: block;
      .mobile_details-content {
        animation-name: menu;
        animation-duration: 0.7s;
        animation-delay: 0.03s;
        animation-fill-mode: forwards;
      }
      @keyframes menu {
        0% {
          right: -276px;
        }
        100% {
          right: 0;
        }
      }
    }
  }

  // Media
  @media (max-width: 858px) {
    .bottom_nav {
      .links,
      .btn_left {
        display: none;
      }
      .section_left {
        display: block;
      }
    }
  }
  @media (max-width: 644px) {
    .bottom_nav {
      .section_left {
        .btns {
          a {
            &:first-of-type {
              display: none;
            }
            &:nth-of-type(2) {
              display: none;
            }
          }
        }
      }
    }
  }
  @media (max-width: 364px) {
    .bottom_nav {
      .section_left {
        .btns {
          a {
            &:last-of-type {
              display: none;
            }
          }
        }
      }
    }
  }
}
.onscroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  .top_nav {
    overflow: hidden;
    height: 0;
    padding: 0;
    .btns {
      opacity: 0;
      transition: all 0.3s;
    }
    .info {
      opacity: 0;
      transition: all 0.3s;
    }
    .lang {
      opacity: 0;
      transition: all 0.3s;
    }
  }
}
