.why {
  width: 100%;
  padding: 20px 0px;
  .title {
    text-align: center;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    span {
      color: #1b7dc0;
    }
  }
  p {
    text-align: center;
    margin: 10px 0;
    font-size: 17px;
  }
  .items {
    width: 100%;
    margin: 49px 0 0 0px;
    .item {
      margin-bottom: 23px;
      cursor: pointer;
      position: relative;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
      }
      h3 {
        color: #777;
        margin: 23px 0 0 0;
        font-size: 22px;
      }
      p {
        padding: 15px;
        font-size: 14px;
        text-align: center;
        line-height: 1.6;
        margin: 0;
      }
    }
  }
}
