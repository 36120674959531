.facts {
  width: 100%;
  padding: 42px 0;
  margin: 31px 0;
  .title {
    text-align: center;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    color: #222;
  }
  .facts_item {
    margin: 44px 0 0 0;
    .item {
      text-align: center;
      .num {
        width: 120px;
        height: 120px;
        border: 5px solid #1b7dc0;
        margin: 0 auto;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        h4 {
          color: #333;
          margin: 0;
          font-family: "Roboto", sans-serif;
          font-size: 25px;
          direction: ltr;
          font-weight: 700;
        }
      }
      p {
        color: #777;
        margin: 10px 0 0 0;
        font-size: 24px;
        direction: ltr;
      }
    }
  }
}
